.carousel-container {
  position: relative;
  width: 100%;
  max-width: clamp(200px, 100vw, var(--size-lg));
  margin: 0 auto;
}

.image-container {
  position: relative;
}

.carousel-image {
  display: block;
  height: auto;
}

.prev-button {
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
}

.next-button {
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
}

.description-container {
  background-color: hsla(0, 0%, 0%, 0.6);
  padding: 0.5rem;
  color: #fff;
  text-align: center;
  font-size: 1.125rem;
}
