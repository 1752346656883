@import "open-props/gradients";
@import "open-props/colors-hsl";

.navbar {
  display: flex;
  justify-content: space-between;
  position: sticky;
  margin-bottom: 1.5rem;
  background: var(--gradient-2);
  box-shadow: var(--shadow-4);
  padding-inline: 1rem;
  align-items: center;
  font-size: 18px;
}

.menu-toggle {
  display: none;
}

.menu {
  display: grid;
  grid-template-columns: repeat(4, auto);
  gap: 1rem;
  padding: 1rem;
  align-items: center;
  list-style: none;
}

a[href] {
  font-weight: 500;
  color: white;
  text-decoration: inherit;
}

.navbar-menu {
  display: grid;
  align-items: center;
  grid-template-columns: auto 1fr;
}

@media (max-width: 768px) {
  .navbar {
    padding: 0.75rem;
  }
  .menu-toggle {
    display: block;
  }

  .menu {
    flex-direction: column;
    align-items: center;
    display: none;
  }

  .menu.open {
    display: flex;
  }
}

.dropdown {
  position: relative;
  padding-inline: 10px;
  cursor: pointer;
}

button {
  align-items: center;
  font-weight: 500;
  color: #fff;
  background-color: var(--violet-10);
}
.dropdown-menu {
  position: absolute;
  background-color: hsl(var(--gray-6-hsl));
  box-shadow: var(--shadow-2);
  border-radius: var(--radius-2);
  list-style: none;
  padding: 0;
  z-index: var(--layer-important);
}

.dropdown-menu li {
  padding: 0.5rem;
  cursor: pointer;
}

.dropdown-menu li:hover,
.dropdown-menu li:focus-visible,
.dropdown-menu li:focus-within {
  background: var(--violet-4);
  box-shadow: var(--shadow-2);
  border-radius: var(--radius-2);
}

.dropdown-toggle {
  cursor: pointer;
  box-shadow: var(--shadow-1);
}

.dropdown-toggle:hover,
.dropdown-toggle:focus-visible,
.dropdown-toggle:focus-within {
  box-shadow: var(--shadow-3);
  outline-style: solid;
  outline-color: var(--violet-4);
  outline-offset: 2px;
  outline-width: var(--border-size-2);
}
/* .slide-fade {
  animation: var(--animation-fade-in), var(--animation-slide-in-down);
  animation-timing-function: var(--ease-out-3);
  animation-duration: 0.5s;
} */
