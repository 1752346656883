/* CSS RESET */

* {
  margin: 0;
}
html:focus-within {
  scroll-behavior: smooth;
}
body {
  min-height: 100vh;
  text-rendering: optimizeSpeed;
}
/* Make images easier to work with */
img,
picture,
svg {
  max-width: 100%;
  display: block;
}

main {
  padding-inline: 2rem;
  text-align: center;
}

h1 {
  font-size: 2rem;
  color: #ffffff;
}

h2 {
  margin-bottom: 0.25rem;
}

section {
  padding-block: 1.5rem;
  margin-block: 2rem;
}

/* Custom Properties */
