footer {
  color: #fff;
  background-color: var(--violet-7);
  box-shadow: var(--shadow-2);
  padding: 10px;
  position: fixed;
  bottom: 0;
  width: 100%;
  text-align: center;
  z-index: 100;
}
